<template>
  <div style="min-height: 500px;" class="collectionNotImg">
    <div v-if="shopList&&shopList.length">
      <div class="table">
        <div class="tableHeader m-t-25">
          <div class="cell-1">
          </div>
          <div class="cell-2">店铺信息</div>
          <div class="cell-3">星级</div>
          <div class="cell-4">关注人数</div>
          <div class="cell-5">操作</div>
        </div>
        <div class="table-item" v-for="item in shopList" :key="item.id">
          <div class="table-boby m-t-25">
            <div class="cell-1">
              <el-checkbox @change="handleCheckChange(item.id,item.isCheck)" v-model="item.isCheck"></el-checkbox>
            </div>
            <div class="cell-2">
              <div class="cell-2-box">
                <img
                  :src="item.enterpriseInfo&&$oucy.ossUrl+item.enterpriseInfo.enterpriseAvata"
                  alt
                  class="goods-img"
                />
                <span
                  class="goods-txt2"
                >{{item.enterpriseInfo&&item.enterpriseInfo.enterpriseShopName}}</span>
              </div>
            </div>
            <div class="cell-3 goods-txt3" v-if="item.enterpriseInfo">
              <el-rate v-model="item.enterpriseInfo.enterpriseStar" disabled text-color="#ff9900"></el-rate>
            </div>
            <div
              class="cell-4 goods-txt4"
            >{{item.enterpriseInfo&&item.enterpriseInfo.enterpriseFansCount}}</div>
            <div class="cell-5 goods-txt5">
              <div class="cell-5-box">
                <el-button
                  size="mini"
                  class="cell-5-btn"
                  :style="item.collectTopping?'background:#2090FF;color:#FFFFFF':''"
                  @click="collectToppingFn(item.id)"
                >
                  <div>{{item.collectTopping?'取消置顶':'置顶'}}</div>
                </el-button>
                <el-button
                  size="mini"
                  class="cell-5-btn"
                  @click="$oucy.go('/ShopVip?enterpriseId='+item.collectId)"
                >
                  <div>分享</div>
                </el-button>
                <el-button size="mini" class="cell-5-btn" @click="isCollectFn(item.id)">
                  <div>删除</div>
                </el-button>
              </div>
            </div>
          </div>
          <div class="link"></div>
        </div>
      </div>
      <div class="bottom-cell-1">
        <div class="bottom-cell-2">
          <div class="bottom-select">
            <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          </div>

          <div class="bottom-btn-box">
            <!-- <el-button size="mini" class="bottom-btn">
              <div>分享</div>
            </el-button>-->
            <el-button size="mini" class="cell-5-btn" @click="allDelBtn">
              <div>删除</div>
            </el-button>
          </div>
        </div>
        <div>
          <div class="pagination" style="background-color: #ffffff;padding: 20px 0;">
            <el-pagination
              v-if="true"
              background
              layout="sizes, prev, pager, next, jumper, ->, total, slot"
              :page-size="10"
              :total="totalElements"
              @current-change="currentChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="collection-not">
      <img src="@/assets/images/photo/collection_not.png" />
      <div class="c-2 m-t-50 f14">您还未收藏过任何商品/店铺/个人名片/企业名片~</div>
    </div>
  </div>
</template>

<script>
import { collectuser } from "@/service";
export default {
  created() {
    // console.log("挂载店铺");
    this.getCollectShop();
  },

  data() {
    return {
      value: 0,
      checkAll: false,
      shopList: [], //收藏店铺数据
      checkList: [], //多选框数据
      iscollectId: { id: "", collectType: 1 }, //删除
      collectId: { id: "" },
      totalElements: null,
      paramsShop: { collectType: 1, limit: 10, start: 0 } //请求收藏店铺参数 collectType:1店铺 2商品
    };
  },
  methods: {
    // 请求：收藏的店铺
    getCollectShop(obj) {
      obj = this.paramsShop;
      collectuser.getCollectType(obj).then(res => {
        let { content, totalElements } = res;
        console.log("店铺", content);
        //店铺数据增加isCheck字段
        this.shopList = content&&content.map(item => {
          item.isCheck = false;
          return item;
        });
        //店铺
        this.shopList = content;
        //总页数
        this.totalElements = totalElements;
      });
    },
    //店铺单选框
    handleCheckChange(id, value) {
      console.log("value", value);
      console.log("id", id);
      if (value) {
        this.checkList.push(id);
      } else {
        let n = this.checkList.indexOf(id);
        this.checkList.splice(n, 1);
      }
      console.log(146,this.checkList);
      this.checkAll = this.checkList.length === this.shopList.length;
    },
    //店铺全选框
    handleCheckAllChange(val) {
        this.shopList.forEach(item => {
        item.isCheck = val;
      });
      if (val) {
        this.checkList = []
      this.shopList.forEach(item => {
        this.checkList.push(item.id); 
      })
      }else{
        this.checkList = []
      }
        console.log("全选数组",this.checkList);
    },
    // 分页器:
    // 第几页
    currentChange(page) {
      this.paramsShop.start = page - 1;
      collectuser.getCollectType(this.paramsShop).then(res => {
        let { content } = res;
        this.shopList = content;
      });
    },
    //置顶
   async collectToppingFn(id) {
      this.collectId.id = id;
      //点击置顶
     await collectuser.collectTopping(this.collectId);
      //刷新页面
      this.getCollectShop(this.paramsShop);
    },
    //删除
   async isCollectFn(id) {
      // this.iscollectId.id = id;
    // await  collectuser.delCollectUser(this.iscollectId);
     console.log('单选参数',{ids:JSON.stringify([id])});
    await  collectuser.batchDelCollectUser({ids:JSON.stringify([id])});
      //刷新页面
      this.getCollectShop(this.paramsShop);
    },
    //多全选删除
   async allDelBtn(){
      console.log("checkList",this.checkList);
      //全选删除
       console.log('全选参数',{ids:JSON.stringify(this.checkList)});
    await  collectuser.batchDelCollectUser({ids:JSON.stringify(this.checkList)})
       //刷新页面
      this.getCollectShop(this.paramsShop);
      this.checkList=[]
    }
  }
};
</script>

<style lang="less" scoped>
.collection-not {
  margin-top: 100px;
}
.link {
  border-top: 1px solid #f6f7f8;
  margin-top: 10px;
  margin-left: 20px;
}
.collectionNotImg {
  width: 100%;
  display: block;
  margin: 21px auto;
  .cell-1 {
    flex: 1;
  }

  .cell-2 {
    .cell-2-box {
      display: flex;
    }
    flex: 6;
    .goods-txt2 {
      width: 274.4px;
      height: 24.5px;
      line-height: 14px;
      font-size: 12px;
      font-family: MicrosoftYaHei-, MicrosoftYaHei;
      font-weight: normal;
      color: #555c68;
      text-align: left;
      margin: auto;
    }
  }

  .cell-3 {
    flex: 4;
  }
  .cell-4,
  .cell-5 {
    flex: 3;
  }
}
.tableHeader {
  width: 100%;
  display: flex;
  background: #fafafa;
  border-radius: 1.4px;
  padding: 7px;
  box-sizing: border-box;
  font-size: 9.8px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #555c68;
}
.table-item {
  display: flex;
  flex-direction: column;
  .table-boby {
    display: flex;
    justify-content: center;
    align-items: center;
    .goods-img {
      width: 64px;
      height: 64px;
      border-radius: 2.8px 2.8px 2.8px 2.8px;
      opacity: 1;
    }
    .goods-txt3 {
      width: 40px;
      height: 13.3px;
      font-size: 9.8px;
      font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
      font-weight: 550;
      // color: #dddddd;
      color: #ed6732;
      line-height: 0px;
      margin: auto;
    }
    .goods-txt4 {
      width: 40px;
      height: 13.3px;
      font-size: 10px;
      font-family: MicrosoftYaHei-Bold-, MicrosoftYaHei-Bold;
      font-weight: normal;
      // color: #dddddd;
      line-height: 0 px;
      margin: auto;
    }
    .goods-txt5 {
      .cell-5-box {
        display: flex;
        flex-direction: column;
        margin-left: -18px;
        padding-left: 7px;
        .cell-5-btn {
          margin: 5px auto;
          width: 75px;
          height: 25px;
          background: #f6faff;
          border-radius: 2px 2px 2px 2px;
          opacity: 1;
          border: 1px solid #cfe0f0;
          div {
            line-height: 5px;
            text-align: center;
          }
        }
      }
    }
    .el-button + .el-button {
      margin-left: 0px;
      margin-top: 5.6px;
    }
  }
}
.bottom-cell-1 {
  .bottom-cell-2 {
    .bottom-select {
      margin-left: 25.5px;
    }
    display: flex;
    margin-top: 25px;
  }
  display: flex;
  justify-content: space-between;
  .bottom-btn-box {
    margin-left: 20px;
    .bottom-btn {
      margin: auto 5px;
      width: 68px;
      height: 25.2px;
      background: #f6faff;
      border-radius: 1.4px 1.4px 1.4px 1.4px;
      opacity: 1;
      border: 1 px solid #cfe0f0;
      div {
        line-height: 4.9px;
        width: 22.4px;
        height: 15.2px;
        font-size: 14;
        padding: 3px 0 0 5px;
      }
    }
  }
}
</style>